//partial
@import "../partials/base";
// @import "../partials/button";

.btn-primary {
    background: #f8d340 !important;
    color: #4a4a4a;
    border-radius: 100px !important;
    border: none !important;
    &:hover {
        background: #d3b022 !important;
        border-radius: 100px !important;
        color: #fff;
        border: none !important;
    }
}

// header navbar
.navbar {
    background-color: white;
    .navbar-brand {
        img {
            max-height: 80px;
        }
    }
    .nav-link {
        color: black !important;
    }
}
// end of header navbar
//
// footer
.contactus {
    background: #fff;
    padding-top: 30px;
    .three-person {
        max-width: 20px;
        transform: scale(12) translateY(-3px);
    }
    .help-txt {
        color: $boxfulGreen;
        font-size: 24px;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        .help-one {
            color: rgb(74, 74, 74);
        }
        .help-img {
            max-width: 40px;
        }
    }
}
.logos {
    img {
        height: auto;
        max-width: 150px;
        margin: 20px;
        max-height: 100px;
    }
}
.business-footer {
    background: $boxfulGreen;
    padding: 40px 0 60px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    a {
        color: #fff;
    }
    .bf-list {
        display: flex;
    }
    .bf-item {
        margin-left: 80px;
        .bf-title {
            font-weight: bold;
        }
        .bf-li {
            margin-top: 7px;
        }
    }
    .bf-contact {
        margin-right: 80px;
        text-align: right;
        .bfc-logo {
            img {
                height: 70px;
                transform: translateX(25px);
            }
        }
        .bfc-tel {
            margin-top: 8px;
            img {
                margin-right: 3px;
            }
        }
        .bfc-adr {
            margin-top: 8px;
        }
    }
}
// end of footer

// max-width: 767px
@media screen and (max-width: 1200px) {
    .navbar {
        .navbar-brand {
            img {
                max-width: 60vw;
            }
        }
    }
    //footer
    .contactus {
        .div-three-person {
            display: none;
        }
        .help-txt {
            text-align: center;
            font-size: 14px;
            .help-row {
                width: 100%;
                margin-left: auto !important;
                margin-right: auto !important;
            }
        }
    }
    .business-footer {
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        .bf-list {
            justify-content: space-around;
            width: 90%;
            .bf-item {
                margin-left: 0;
            }
        }
        .bf-contact {
            width: 90%;
            margin-top:20px;
            margin-right: 0;
            text-align: center;
            .bfc-logo {
                width: 100%;
                img {
                    height: auto;
                    max-width: 50%;
                    transform: translateX(0px);
                }
            }
        }
    }
}

// dialog
div#boxfulMessage {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #f3f3f3;
    background: #f3f3f3;
    padding: 0;
    position: fixed;
    box-shadow: -7px 7px 7px rgba(0,0,0,0.3);
    width: 350px !important;
    z-index: 10001 !important;
    .content-wrap {
        padding: 2em 3em;
        text-align: center;
        font-size: 16px;
    }
    .btn-wrap {
        display: flex;
        width: 100%;
        .btn-1 {
            width: 100%;
            padding: .6em 0;
            background: #f7d33f;
            font-size: 20px;
            text-transform: uppercase;
            color: #000;
            cursor: pointer;
            border: 0 none;
        }
        .btn-2 {
            width: 100%;
            padding: .6em 0;
            background: #ccc;
            font-size: 20px;
            text-transform: uppercase;
            color: #000;
            cursor: pointer;
            border: 0 none;
        }
    }
}
// load
.loader-shadow {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .3);
}
.loader-msg {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 999999;
    font-size: 14px;
    transform: translate(-50%, 40px);
}
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #00c4b3;
  font-size: 10px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999999;
  text-indent: -9999em;
  -webkit-transform: translateZ(0) translate(-50%, -50%);
  -ms-transform: translateZ(0) translate(-50%, -50%);
  transform: translateZ(0) translate(-50%, -50%);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
